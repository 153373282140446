@import 'normalize.css';

* {
    outline: none !important;
}

html {
    font-size: 62.5%;
    font-family: Muli, Roboto, Helvetica Neue, Arial, sans-serif;
    background-color: #262933;
}

body {
    font-size: 14px;
    line-height: 1.4;
}

html, body, #root {
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: relative;
}

h1, .h1 {
    font-size: 24px;
}

h2, .h2 {
    font-size: 20px;
}

h3, .h3 {
    font-size: 16px;
}

h4, .h4 {
    font-size: 15px;
}

h5, .h5 {
    font-size: 13px;
}

h6, .h6 {
    font-size: 12px;
}

@font-face {
    font-family: 'ProximaNova';
    src: url('./fonts/proxima_nova_reg-webfont.ttf') format('truetype');
}
@font-face {
    font-family: 'ProximaNovaLight';
    src: url('./fonts/Proxima-Nova-Light.otf') format('opentype');
}
@font-face {
    font-family: 'ProximaNovaBold';
    src: url('./fonts/Proxima-Nova-Bold.otf') format('opentype');
}
@font-face {
    font-family: 'Myriad';
    src: url('./fonts/Myriad-Pro-Regular.ttf') format('truetype');
}
@font-face {
    font-family: 'MyriadBold';
    src: url('./fonts/myriadpro-bold.otf') format('opentype');
}
@font-face {
    font-family: 'MyriadLight';
    src: url('./fonts/myriadpro-light.otf') format('opentype');
}


.ps > .ps__rail-y,
.ps > .ps__rail-x {
    z-index: 99;
}

a[role=button] {
    text-decoration: none;
}


form label {
    z-index: 99;
}

.DocManager .showHide .selfie, .DocManager .showHide .poaddress, .DocManager .showHide .photoid, .DocManager .showHide .uploadProgress{
    display:none;
}

  .DocManager .showHide.uploading .uploadProgress{
    display:block;
  }

  .DocManager .showHide.photograph .selfie{
    display:block;
  }

  .DocManager .showHide.photograph .selfie img{
    background-image: url('/assets/images/profile/default_photo.jpg');
    background-color:#31ade1;
    background-size: contain;
    background-repeat: no-repeat;
    min-height: 169.28px;
    min-width: 100%;
  }

  .DocManager .showHide.passport .photoid{
    display:block;
  }

  .DocManager .showHide.passport .photoid img{
    background-image: url('/assets/images/profile/passport.jpg');
    background-color:#31ade1;
    background-size: contain;
    background-repeat: no-repeat;
    min-height: 169.28px;
    min-width:100%;
  }

  .DocManager .showHide.bill .poaddress{
    display:block;
  }

  .DocManager .showHide.bill .poaddress img{
    background-image: url('/assets/images/profile/bill.jpg');
    background-color:#31ade1;
    background-size: contain;
    background-repeat: no-repeat;
    min-height: 169.28px;
    min-width:100%;
  }

  .DocManager .showHide{
    position: relative;
  }

  .DocManager .uploadProgress{
    position: absolute;
    z-index: 1000;
    background: rgba(0,0,0,.8);
    text-align: center;
    font-size: 25px;
    width: 100%;
    height: 100%;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    color: white;
    border-radius: 10px;
    padding-top: 34%;
  }

  .DocManager .picpresent{
        text-align:center;
        border-radius:4px !important;
        font-size:14px;
  }
.samplePhoto {
    margin-top: 14.72px;
    width: 100%;
    height:auto;
    border: none;
    background-size: 100%;
    /*background-image: url('../assets/default_photo.jpg');*/
    border:1px solid black;
}

.labelPreview {
    padding: 6px 21px;
    float: left;
    text-align: center;
    font-size: 14px;
    color: white;
    background-color: #039be5;
    position: absolute;
    left: 0vh;
    bottom: 100px;
  }

.fontawesome-icon-list a {
    border-bottom: 1px dashed #d9d9d9;
    color: #333333;
    float: left;
    font-family: Roboto;
    font-size: 14px;
    padding: 10px 0;
    pointer-events: none;
    width: 100%;
}
.IdentityPopup, .AddressPopup, .UploadPopup{
    width:500px;
    height:auto;
    top:50%;
    margin-top:-200px;
    left:50%;
    margin-left:-250px;
    z-index: 10000;
    position: absolute;
    background:white;
    padding:0px;
    border:2px solid red;
    border-radius:3px;
    box-shadow: 5px 5px 5px rgba(0,0,0,0.4);
}
.IdentityPopup .profile-widget-head:before, .AddressPopup .profile-widget-head:before{
    background-color: red;
    border-radius:0px;
}
.IdentityPopup.display, .AddressPopup.display{
    display:block;
}

.IdentityPopup.hide, .AddressPopup.hide{
    display:none;
}

.display {
    display: block;
}
.clearfix{
    clear:both;
    float:none;
}
.hide {
    display: none;
}
.titleIPO {
    display: inline-block;
    width: 100%;
    margin: 1vh 0;
    color: #FFFFFF;
    font-size: 20px;
    font-weight: 600 ;
    letter-spacing: 1px;
}
.IPOCard .quick-stats .hundred{
    width:100%;
}
.ebf-grey span{
    background: #575b61 !important;
}
.IPOCard .quick-stats .hundred{
    width:100%;
}

  .picpresent.buttonStateRed {
    border:2px solid red;
  }

  .picpresent.buttonStateRed span, .tabstate.buttonStateRed{
    color:red;
  }

  .picpresent.buttonStateRed span .uploaded, .tabstate.buttonStateRed .uploaded{
    display:none;
  }

  .picpresent.buttonStateRed span .pending, .tabstate.buttonStateRed .pending{
    display:none;
  }

  .picpresent.buttonStateRed span .notuploaded, .tabstate.buttonStateRed .notuploaded{
    display:inline;
  }

  .picpresent.buttonStateGreen {
    border:2px solid green;
  }

  .picpresent.buttonStateGreen span, .tabstate.buttonStateGreen{
    color:green;
  }

  .picpresent.buttonStateGreen span .uploaded, .tabstate.buttonStateGreen .uploaded{
    display:inline;
  }

  .picpresent.buttonStateGreen span .pending, .tabstate.buttonStateGreen .pending{
    display:none;
  }

  .picpresent.buttonStateGreen span .notuploaded, .tabstate.buttonStateGreen .notuploaded{
    display:none;
  }

  .picpresent.buttonStateOrange {
    border:2px solid rgb(248, 158, 29);
  }

  .picpresent.buttonStateOrange span, .tabstate.buttonStateOrange{
    color:rgb(248, 158, 29);
  }

  .picpresent.buttonStateOrange span .uploaded, .tabstate.buttonStateOrange .uploaded{
    display:none;
  }

  .picpresent.buttonStateOrange span .pending, .tabstate.buttonStateOrange .pending{
    display:inline;
  }

  .picpresent.buttonStateOrange span .notuploaded, .tabstate.buttonStateOrange .notuploaded{
    display:none;
  }

.IPOCard .quick-stats ul{
    margin-bottom:10px;
}

.IPOCard .quick-stats ul > li{
    width:50%;
}

.IPOCard .quick-stats ul:after {
    content: "";
    display: table;
    clear: both;
}

.progress-skills{
    padding-left:45px;
    position:relative;
    margin-top:27px;
}

.progress-skills > h3 {
    color: #111;
    float: left;
    font-size: 15px;
    letter-spacing: 0.3px;
    margin: 0;
    position: absolute;
    top:-17px;
    left:0px;
}
.progress-skills > h3 > i {
    background: none repeat scroll 0 0 #f0ad4e;

    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    -o-border-radius: 3px;
    border-radius: 3px;

    color: #000;
    font-size: 21px;
    height: 40px;
    line-height: 40px;
    margin-right: 15px;
    text-align: center;
    width: 40px;
}
.progress-skills > .progress {
    border-radius: 3px;
    box-shadow: none;
    float: left;
    height: 6px;
    margin: 0;
    overflow: visible;
    width: 100%;
    background:#cfcfcf;
}
.progress-skills > .progress > .progress-bar {
    background: #f0ad4e;
    border-radius: 3px;
    box-shadow: none;
    float: left;
    position: relative;
}
.progress-skills > .progress > .progress-bar:before {
    background: none repeat scroll 0 0 #FFFFFF;
    content: "";
    height: 22px;
    position: absolute;
    right: 0;
    top: -8px;
    width: 1px;
}
.progress-skills > .sr-only {
    color: 'white';
    float: right;
    font-size: 12px;
    height: auto;
    margin-top: -25px;
    position: static;
    text-align: right;
    width: 100%;
}

.progress-skills > .sr-only.left {
    color: 'white';
    float: left;
    font-size: 12px;
    height: auto;
    margin-top: -25px;
    position: static;
    text-align: left;
    width: 100%;
}

.progress-skills > .sr-only.bottom {
    color: 'white';
    float: right;
    font-size: 12px;
    line-height:20px;
    height: auto;
    margin-top: 0px;
    position: static;
    text-align: right;
    width:auto;
}

.progress-skills > .sr-only.bottom.left {
    color: 'white';
    float: left;
    font-size: 12px;
    line-height:20px;
    height: auto;
    position: static;
    text-align: left;
    width:auto;
}
.quick-stats ul > li{
    width:50%;
}
.quick-stats {
    /*float: left;*/
    width: 100%;

    -webkit-transition: all 0.4s ease 0s;
    -moz-transition: all 0.4s ease 0s;
    -ms-transition: all 0.4s ease 0s;
    -o-transition: all 0.4s ease 0s;
    transition: all 0.4s ease 0s;

}
.quick-stats > h5 {
    color: #fff;
    float: left;
    font-family: Lato;
    font-size: 11px;
    font-weight: bold;
    letter-spacing: 0.3px;
    line-height: 8px;
    margin: 15px 0 11px;
    text-transform: uppercase;
    width: 100%;

    -webkit-transition: all 0.4s ease 0s;
    -moz-transition: all 0.4s ease 0s;
    -ms-transition: all 0.4s ease 0s;
    -o-transition: all 0.4s ease 0s;
    transition: all 0.4s ease 0s;

}
.quick-stats > ul {
    list-style: none outside none;
    -webkit-transition: all 0.4s ease 0s;
    -moz-transition: all 0.4s ease 0s;
    -ms-transition: all 0.4s ease 0s;
    -o-transition: all 0.4s ease 0s;
    transition: all 0.4s ease 0s;

}
.quick-stats > ul > li {
    /*float: left;*/

    -webkit-transition: all 0.4s ease 0s;
    -moz-transition: all 0.4s ease 0s;
    -ms-transition: all 0.4s ease 0s;
    -o-transition: all 0.4s ease 0s;
    transition: all 0.4s ease 0s;

}
.quick-stats > ul > li > span {
    background: none repeat scroll 0 0 #383c42;
    color: #fff;
    float: left;
    font-size: 19px;
    padding: 14px 0;
    text-align: center;
    width: 100%;

    -webkit-transition: all 0.4s ease 0s;
    -moz-transition: all 0.4s ease 0s;
    -ms-transition: all 0.4s ease 0s;
    -o-transition: all 0.4s ease 0s;
    transition: all 0.4s ease 0s;

}
.quick-stats > ul > li > span i {
    color: #babec9;
    float: left;
    font-size: 11px;
    font-style: normal;
    letter-spacing: 0.3px;
    line-height: 12px;
    text-transform: uppercase;
    width: 100%;

    -webkit-transition: all 0.4s ease 0s;
    -moz-transition: all 0.4s ease 0s;
    -ms-transition: all 0.4s ease 0s;
    -o-transition: all 0.4s ease 0s;
    transition: all 0.4s ease 0s;

}

.onlyprint {
    display: none;
}

.progress-skills > .progress > .progress-bar{
    border:3px solid #f0ad4e;
}

.amChartsButtonSelected {
  color: #ffffff;
  background-color: #343A47;
  margin: 0 2px 0 2px;
}

.amChartsButton {
  color: #ffffff;
  background-color: #371616;
  margin: 0 2px 0 2px;
}

.fa-exclamation-circle {
  color: #EC3324;
}

@import 'print.css';

@import 'tables.css';

@import 'react-table.css';

@import 'prism.css';

@import 'fuse-helpers.css';

::-webkit-scrollbar {
    width: 11px;
}

/* Track */
::-webkit-scrollbar-track {
    border-radius: 10px;
    background:transparent;
}

::-webkit-scrollbar-thumb {
    -webkit-border-radius: 10px;
    border-radius: 10px;
    background: rgba(0,0,0,0.2);
}

.picpresent.buttonStateRed {
    border:2px solid red;
}

.picpresent.buttonStateRed span, .tabstate.buttonStateRed{
    color:red;
}

.picpresent.buttonStateRed span .uploaded, .tabstate.buttonStateRed .uploaded{
    display:none;
}

.picpresent.buttonStateRed span .pending, .tabstate.buttonStateRed .pending{
    display:none;
}

.picpresent.buttonStateRed span .notuploaded, .tabstate.buttonStateRed .notuploaded{
    display:inline;
}

.picpresent.buttonStateGreen {
    border:2px solid green;
}

.picpresent.buttonStateGreen span, .tabstate.buttonStateGreen{
    color:green;
}

.picpresent.buttonStateGreen span .uploaded, .tabstate.buttonStateGreen .uploaded{
    display:inline;
}

.picpresent.buttonStateGreen span .pending, .tabstate.buttonStateGreen .pending{
    display:none;
}

.picpresent.buttonStateGreen span .notuploaded, .tabstate.buttonStateGreen .notuploaded{
    display:none;
}

.picpresent.buttonStateOrange {
    border:2px solid rgb(248, 158, 29);
}

.picpresent.buttonStateOrange span, .tabstate.buttonStateOrange{
    color:rgb(248, 158, 29);
}

.picpresent.buttonStateOrange span .uploaded, .tabstate.buttonStateOrange .uploaded{
    display:none;
}

.picpresent.buttonStateOrange span .pending, .tabstate.buttonStateOrange .pending{
    display:inline;
}

.picpresent.buttonStateOrange span .notuploaded, .tabstate.buttonStateOrange .notuploaded{
    display:none;
}